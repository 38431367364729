import { isUserLoggedIn, pushDataLayerEventHandler, removeCookies, removeParam, showErrorToast } from "@/utils/utils"
import { parsePropertyTypeForm } from "services/matchmakerService"
import { SYRConstant, pageType as current_page, errorLable, matchmakerEvents, propertyCategory } from "./MatchmakerConstant"
import { removeLocalStorageParams } from "./MatchMakerUtils"
import { syrAnalyticsLite } from "services/postLiteService"
import { commonApi } from "./MMDeeplink/services"
import uniqueIdGenerator from '@/utils/generateUniqueId'

export const srpRedirection = (router, variant, lang, getRequirementData, city_ID, eventName='MM_success_page_close') => {
    if (typeof window !== 'undefined') {
      pushDataLayerEventHandler(window, lang, eventName, 'form_submit_success_screen', {variant, request_id: router?.query?.requirementId})
      localStorage.setItem('MatchmakerSuccess', true)
    }   
    const requirement_Data = getRequirementData?.requirement?.[0] || {}
    if (Object.keys(requirement_Data).length !== 0 ) {
    const propertyTypeData = parsePropertyTypeForm(requirement_Data?.propertyTypeSlug)
    const propertyFor = requirement_Data?.purposeSlug === SYRConstant.REQ_RENT ? SYRConstant.RENT : SYRConstant.SALE 
    const bedroomStr = requirement_Data?.bedrooms?.length ? `${requirement_Data?.bedrooms?.replace(/،/g, ',')?.replace(/\s/g, '')}` : ''
    const property_Category = (requirement_Data?.lookingFor?.toLowerCase() === propertyCategory.RESIDENTIAL || requirement_Data?.lookingFor?.toLowerCase() === 'سكني') ? propertyCategory.RESIDENTIAL : propertyCategory.COMMERCIAL
    router.replace(getSRPUrl(requirement_Data, propertyTypeData, propertyFor, bedroomStr, city_ID, property_Category))
    } 
}

export const getTransactionDetails = async (requirementId, setTransactionDetail , lang , Slug) => {
    try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_GATEWAY_URL}subscription-manager/purchase-details`,
      {
        method: 'POST',
        headers: {
          locale: lang,
          Authorization: window.localStorage.getItem('authToken') ?? '',
          module: 'wasalt',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
         'entityType': "mm_requirement",
         'entityId': requirementId,
         'grpSlug': Slug
        }),
      },
    )
    const res = await response.json()
    if (res?.status) {
     setTransactionDetail(res?.data)
    }
  } catch (error) {
    console.error("Error Occur", error)
  }
  }

  export const getMatchingAgents = async (setAgentData,requirementId,lang,onPaymentPage=false) => {
    const queryParams = `requirementId=${requirementId}&onPaymentPage=${onPaymentPage}`
    const response = await fetch(
      `/api/generic?apiUrl=${encodeURIComponent(
        `${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/get-assigned-agents?${queryParams}`,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
          Authorization: window.localStorage.getItem('authToken'),
        },
      },
    )

    const res = await response.json()
    if (res?.data?.status) {
      setAgentData(res?.data?.data)
    } else {
      showErrorToast(res?.data?.message)
    }
  }


  export const CheckPaymentEntities = async (lang, setPaymentEntities) => {
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}subscription-manager/payment-entities`, {
        method: 'GET',
        headers: {
          locale: lang,
          Authorization: window.localStorage.getItem('authToken') ?? '',
          module: 'wasalt',
          'current-app': 'wasalt',
          'product-type': 'subscription_package',
          'x-request-id': uniqueIdGenerator.getId()
        },
      })
      const res = await response.json()
      if (res?.status) {
        setPaymentEntities(res?.data)
      }
    } catch (error) {
      console.error('Error Occur', error)
    }
  }

  const calculatePrice = (price , vat) => Math.round(price + (price*vat)/100)

  export const getBrokerCount = async (lang, dispatch) => {
    try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_GATEWAY_URL_V2}subscription-manager/package/mm-instant-broker`,
      {
        method: 'GET',
        headers: {
          locale: lang,
          Authorization: window.localStorage.getItem('authToken') ?? '',
          module: 'wasalt',
          'x-request-id': uniqueIdGenerator.getId()
        },
      },
    )
    const res = await response.json()
    if (res?.status) {
      dispatch({ type: 'SET_TOTAL_AMOUNT_FOR_FEATURE_REQUEST', payload: calculatePrice(res?.data?.packages?.[0]?.price,res?.data?.packages?.[0]?.vat)})
      dispatch({ type: 'SET_BROKER_COUNT', payload: res?.data?.packages?.[0]?.offerings?.mmBrokersOfrd})
      dispatch({ type: 'SET_PACKAGE_COUNT', payload: res?.data?.packages?.[0]?.id})
    }
  } catch (error) {
    console.error("Error Occur", error)
  }
  }


export const handleDataLayerforbackButton = (lang, router, userEligible, requirementId) => {
  let screen_name = ''
  if(isUserLoggedIn()) {
    if(userEligible === null) {
      screen_name = 'eligibility_loader'
    } else if(userEligible) {
      screen_name = 'premium_upgrade'
    } else {
      screen_name = 'premium_sorry'
    }
  } else {
    screen_name = 'login_loader'
  }
  pushDataLayerEventHandler(window, lang, 'back_button_clicked', 'landing_page', {source : `premium_plan_${router?.query?.source}`, 
  request_id: router?.query?.req_id || requirementId, screen_name})
}


export const paymentPopUPHandler = (lang , setPaymentEntities, currentURL, router, setShowPayment) => {
  CheckPaymentEntities(lang , setPaymentEntities)
  zIndexDecrease(9,'hidden')
  const paramNamesToRemove = ["id", 'resourcePath', "success"];
  currentURL = paramNamesToRemove.map((paramName) => currentURL = removeParam(paramName, currentURL));
  router.replace(currentURL[2])
  setShowPayment(true)
}


export const zIndexDecrease = (count, scrollState) => {
  const getHeader = document.getElementsByTagName('header')[0];
  if(getHeader){
    getHeader.style.zIndex = count
    document.body.style.overflow = scrollState
    getHeader.style.transition = 'all 0.25s linear'
  }
}

export const paymentScreenHandler = (setPaymentFailure, setShowPayment,source, activeCard, requirementId, lang, pageType) => {
  zIndexDecrease(999,'auto')
  document.body.style.overflow = 'auto'
  setPaymentFailure('')
  setShowPayment(false)
  const details = {
    source,
    variant: 'tabular_plan',
    Payment_method_selected: activeCard ? 'applepay ' : 'card',
    request_id : requirementId
  }
  pushDataLayerEventHandler(window, lang, 'MM_pay_screen_back_clicked', pageType, details)
}


export const getTransactionStatues = async ({lang, isMobile, router, setPaymentEntities, featureRequestPrice, requirementId, dispatch, setPaymentFailure, setShowPayment}) => {
  try {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_GATEWAY_URL}subscription-manager/payment-status`,
    {
      method: 'POST',
      headers: {
        locale: lang,
        Authorization: window.localStorage.getItem('authToken') ?? '',
        module: 'wasalt',
        'Content-Type': 'application/json',
        source: isMobile ? 'msite' : 'Website',
        'x-request-id': uniqueIdGenerator.getId()
      },
      body: JSON.stringify({
        "checkoutId" : router?.query?.id
      }),
    },
  )
  const res = await response.json()
  if (res?.status) {
    const isMyRequirement = router?.query?.source === 'my_requirement' ? 'my_requirement' : 'form_submit_success_screen'
    const currentPage = router?.query?.source ? isMyRequirement : 'requirement_details'
    CheckPaymentEntities(lang , setPaymentEntities)
    if (res?.data?.isTransactionSuccess) {
      pushDataLayerEventHandler(window, lang, 'MM_card_payment_success', currentPage, {source : router?.query?.source ?? 'requirement_details', variant: 'tabular_plan', payment_amount: featureRequestPrice, request_id : requirementId})
      if (router?.pathname == '/user/myrequirements/[req-id]') {
        const language = lang === 'en' ? '/en/' : '/' 
        window.history.pushState(null, '', `${language}user/myrequirements/agentdetails?requirementId=${requirementId}&success=true&source=${router?.query?.source ?? 'requirement_details'}&onpaymentpage=true`)
        router.reload()
      } else {
        router.push(`/user/myrequirements/agentdetails?requirementId=${requirementId}&success=true&source=${router?.query?.source ?? 'requirement_details'}`)
      }
    } else {
      zIndexDecrease(999,'auto')
      dispatch({ type: 'SET_BOTTOM_SHEET_BACKDROP', payload: false})
      zIndexDecrease(9)
      setPaymentFailure(res?.message)
      pushDataLayerEventHandler(window, lang, 'MM_card_payment_failed', currentPage, {source : router?.query?.source ?? 'requirement_details', variant: 'tabular_plan', Error_description: res?.message, request_id : requirementId})
      setShowPayment(true)
    }
    
  }
  } catch (error) {
    console.error("Error Occur", error)
  }
}


export const getCheckoutId = async ({lang,packageId,requirementId,paymentType,setShowCardInfo,setPaymentCheckoutId,source,activeCard ,pageType}) => {
  try {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_GATEWAY_URL_V2}subscription-manager/initiate-checkout`,
    {
      method: 'POST',
      headers: {
        locale: lang,
        Authorization: window.localStorage.getItem('authToken') ?? '',
        module: 'wasalt',
        'Content-Type': 'application/json',
        'x-request-id': uniqueIdGenerator.getId()
      },
      body: JSON.stringify({
        "subsPkg": [
          {
              id: packageId,
              quantity: 1,
              entityType: "mm_requirement",
              entityId: requirementId,
          }
        ],
        entityName: paymentType,
        currencyCode:"sar"
      }),
    },
  )
  const res = await response.json()
  if (res?.status) {
    setShowCardInfo(true)
    setPaymentCheckoutId(res?.data?.checkoutId)
    const details = {
      source,
      variant: 'tabular_plan',
      Payment_method_selected: activeCard ? 'applepay ' : 'card',
      request_id : requirementId
    }
    pushDataLayerEventHandler(window, lang, 'MM_payment_card_details_load', pageType, details)
  }
  } catch (error) {
    console.error("Error Occur", error)
  }
}

export const getMonetisationAbTesting =(isEnabled,uCookies)=>{
  if(isEnabled) {
    return uCookies?.cookies?.AB_listingCard === 'A' ? 'A' : 'B'
  }
  return 'A'
}

export const getPayementDropOff = async (lang, requirementId) => {
  try {
  await fetch(
    `${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/get-payment-dropout-requirement`,
    {
      method: 'POST',
      headers: {
        locale: lang,
        Cookie: `i18next=${lang}`,
        'Content-Type': 'application/json',
        'x-request-id': uniqueIdGenerator.getId()
      },
      body: JSON.stringify({
        requirementId
      }),
    },
  )
  } catch (error) {
    console.error("Error Occur", error)
  }
}

export const getSRPUrl = (requirement_Data, propertyTypeData, propertyFor, bedroomsCount, city_ID, property_Category) => {
  const baseURL = "/";
  const baseURLParams = "/search"
  const queryParams = {
    propertyFor,
    countryId: 1,
    cityId: city_ID,
    districtId: requirement_Data?.districtIds?.join(','),
    type: property_Category,
    propertyTypeData,
    bedroomsCount,
    min_price: requirement_Data?.min,
    max_price: requirement_Data?.max
  };

  const srpURL = new URL(`${baseURL}${propertyFor}${baseURLParams}`, window?.location?.origin);

  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key] !== undefined && queryParams[key] !== null) {
      srpURL.searchParams.append(key, queryParams[key]);
    }
  });
  return srpURL
}


export const getSuccessPageUrl = (requirementId, source) => {
  const baseURL = "/mmsuccesspage";
  const queryParams = {
    requirementId,
    source,
  };

  const successPageUrl = new URL(`${baseURL}`, window?.location?.origin);

  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key] !== undefined && queryParams[key] !== null) {
      successPageUrl.searchParams.append(key, queryParams[key]);
    }
  });
  return successPageUrl
}

export const cacheClear = () => {
  const keyToDel = ['authToken', 'phoneNumber', 'firstName', 'user_Id', 'userId', 'refreshToken', 'authStoredDate']
  removeLocalStorageParams(keyToDel)
  removeCookies()
}

export const postCloseRequest = async (props) => {
  const {requestFulfilled, setErrors, requirement_id, agentName, reasonSelected, description, setShowcloseRequest, setShowSuccessMsg, setSuccessMessage, setRedirectHome, setRequestFulfilled, showOtherField, reqDetails, lang, t} = props
  if(closeRequestValidation({requestFulfilled, setErrors, agentName, reasonSelected, showOtherField, description, t })) return;
  const reqBody = {
    requirementId: requirement_id,
    fulfilled: requestFulfilled == 'Yes' ? true : false,
    outsideWasalt: agentName == SYRConstant.OUTSIDE_OF_WASALT ? true : false,
    reasonId: reasonSelected,
    reasonDescription: description,
  }

  if (agentName != null && agentName != SYRConstant.OUTSIDE_OF_WASALT) {
    reqBody.agentId = agentName
  }

  const method =  'POST'
  const url = `/api/generic?apiUrl=${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/close-requirements`
  const headers = {
    'Content-Type': 'application/json',
    locale: lang,
    Authorization: window.localStorage.getItem('authToken'),
  }
  commonApi(url, method, headers, errorLable.CLOSE_REUIREMENT , (res) =>  {
    if (res?.data?.status) {
      const eventName = requestFulfilled == 'Yes' ? matchmakerEvents.FORM_Events.SYR_CLOSE_REQ_SUBMIT : matchmakerEvents.FORM_Events.SYR_CLOSE_REQUEST
      syrAnalyticsLite({ window, lang, eventName, data: { ...reqDetails, ...res?.data }, screenName: current_page.AGENT_DETAILS })
      setShowcloseRequest(false)
      setShowSuccessMsg(true)
      setSuccessMessage(t('shareRequirement.Requet_Feedback'))
      setRedirectHome(true)
      setRequestFulfilled(null)
      setErrors({})
    } else {
      showErrorToast(res?.data?.message)
    }
  },JSON.stringify(reqBody))
}

const closeRequestValidation = (props) => {
  const {requestFulfilled, setErrors, agentName, reasonSelected, showOtherField, description, t } = props
  switch (true) {
    case !requestFulfilled:
      setErrors({ requestFulfilled: t('shareRequirement.REQUIRED') });
      return true;
  
    case requestFulfilled === 'Yes' && !agentName:
      setErrors({ agentName: t('shareRequirement.REQUIRED') });
      return true;
  
    case requestFulfilled === 'Yes' && agentName === SYRConstant.OUTSIDE_OF_WASALT && (reasonSelected?.length || 0) <= 0:
      setErrors({ reasonSelected: t('shareRequirement.REQUIRED') });
      return true;
  
    case requestFulfilled === 'No' && reasonSelected?.length <= 0:
      setErrors({ reasonSelected: t('shareRequirement.Selection_Message') });
      return true;
  
    case showOtherField && !description:
      setErrors({ description: t('shareRequirement.REQUIRED') });
      return true;
  
    default:
      return false
  }
}