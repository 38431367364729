import { getDistrict, syrAnalytics } from 'services/postLiteService'
import { kml_data, parsedData } from 'services/matchmakerService'
import { objectRemoveEmptyData } from '@/utils/utils'
import { listingValues } from '@/utils/constant'
import { getWhatsappAbTestVarient, newMatchmakerAnalytics } from './MatchMakerUtils'
import { matchmakerEvents, pageType } from './MatchmakerConstant'

export const jumpToError = (setHasSubmitClicked, isMobile) => {
  setTimeout(() => {
    const elem = document.querySelector(`.error_container`)

    if (!elem) {
      setHasSubmitClicked && setHasSubmitClicked(false)
      return
    }

    if (elem) {
      // Get the height of the fixed header (replace with your header selector)
      const header = document.querySelector('header')
      const headerHeight = header?.offsetHeight || 0

      const isOpenAppHeader = window.sessionStorage.getItem('isOpenHeaderClosed') != null
      const openAppHeaderHeight = !isOpenAppHeader ? 66 : 0
      // Scroll to the top of the element with an offset of the header height
      const elementRect = elem.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.scrollY
      const scrollToPosition = absoluteElementTop - headerHeight - openAppHeaderHeight
      if (isMobile) {
        window.scrollTo({ top: scrollToPosition - 35, behavior: 'smooth' })
      } else {
        window.scrollTo({ top: scrollToPosition + 35, behavior: 'smooth' })
      }
    }
    if (elem?.previousElementSibling?.nodeName === 'INPUT') {
      elem.previousElementSibling.focus()
    }
    setHasSubmitClicked && setHasSubmitClicked(false)
  }, 0)
}

export const handleJumpToError = (errors, context) => {
  const { hasSubmitClicked, setHasSubmitClicked, isMobile } = context
  if (errors && hasSubmitClicked) {
    setTimeout(() => (Object.keys(errors)?.length ? jumpToError(setHasSubmitClicked, isMobile) : null), 1)
  }
  return null
}

export const clearError = (fieldName, submitCount, errors) => {
  if (submitCount >= 1) {
    delete errors[fieldName];
  }
}

// handleling checkbox values add and remove from formik values object.
export const handleCheckboxUpdates = (e, name, setFieldValue, values = []) => {
  const { value } = e.target
  const data = [...values]
  const index = data.indexOf(value)
  if (index === -1) {
    data.push(value)
  } else {
    data.splice(index, 1)
  }
  setFieldValue(name, [...data])
}

// setting initial values for auto-filling form from SRP, PDP and Home page
export const setintialvaluehandler = (propertyFor, styles, context, lang, t, draftData=null, autofillData = null) => {
  const {
    setIsValueInitialized,
    setManuallyChangedValues,
    setInitialValue,
    manuallyChangedValues,
    setIsProjectUnitRent,
    setCityId,
    setEventAutoFillData,
    setCityOrDistrictAutofill,
    setAutoFilledValues,
    setAutofillBudgetClass,
  } = context
  const data = autofillData || parsedData(t, fetchCountryCode(), lang, propertyFor, draftData)
  setIsProjectUnitRent((propertyFor || data?.listingTypeId) === listingValues[1])
  setCityId(manuallyChangedValues?.cityId || data?.cityId)
  const district = manuallyChangedValues?.district
    ? manuallyChangedValues?.district.map((item) => item?.id).join()
    : ''

  fetchDistrict(
    manuallyChangedValues?.cityId || data?.cityId,
    context,
    lang,
    district ? district?.split(',') : data?.district?.split(','),
    true,
  )
  objectRemoveEmptyData(data)
  setEventAutoFillData((prevState) => ({ ...prevState, autofillcounter: Object.entries(data).length - 4 }))
  setCityOrDistrictAutofill({ city: 'cityId' in data, district: 'district' in data })
  // Get the common Field
  const commonField = Object.keys(data).filter((fieldName) =>
    Object.prototype.hasOwnProperty.call(manuallyChangedValues, fieldName),
  )
  // Remove the common Field from data
  commonField.forEach((fieldName) => {
    delete data[fieldName]
  })
  setAutoFilledValues({ ...data })
  if (data?.minPrice || data?.maxPrice) {
    setAutofillBudgetClass({ max: styles.form_group_input_fill_budget })
  }
  data &&
    setInitialValue({
      ...data,
      ...manuallyChangedValues,
      listingTypeId: propertyFor || data?.listingTypeId,
      district: district || data?.district,
    })
  const tempManualObj = { ...manuallyChangedValues }
  setManuallyChangedValues(tempManualObj)
  setTimeout(() => setIsValueInitialized(true), 100)
}

export const fetchCountryCode = () => {
  let countryCodeTemp = ''
  if (typeof window !== 'undefined' && localStorage.getItem('countryCode')) {
    if (localStorage.getItem('countryCode')?.includes('+')) {
      countryCodeTemp = localStorage.getItem('countryCode')
    } else {
      countryCodeTemp = `+${localStorage.getItem('countryCode')}`
    }
  }
  return countryCodeTemp
}

/**
 * Fetches districts on city change
 * @param districtValues - district id array received when autofill is turned ON/OFF
 * @param execDefaultDistrictState - true when autofill is turned ON, false when autofill is turned OFF or default
 */
export const fetchDistrict = async (cityid, context, lang, districtValues = [], execDefaultDistrictState = false) => {
  const {
    setDistrictData,
    setSelectedDistrict,
    setSelectedDistrictFinal,
    zones,
    setCustomZoom,
    setMapCenter,
    setShowMap,
  } = context
  setDistrictData([])
  if (execDefaultDistrictState) {
    setSelectedDistrict([])
    setSelectedDistrictFinal([])
  }
  const cityData = zones?.data?.filter((item) => item.id === cityid?.toString())
  setCustomZoom(Boolean(cityData[0]?.zones?.length)) // setting custom zoom if zone is present
  if (cityData[0]?.isKmlAvailable) {
    setMapCenter(null)
    setShowMap(true)
    const response = await kml_data(lang, cityid, true)
    handleDistrictMapData(response?.data, context, lang, districtValues)
    setMapCenter(cityData[0]?.centerCoordinates)
  } else if (cityid) {
    setMapCenter(null)
    setShowMap(false)
    const response = await getDistrict(cityid, lang) // service method
    setDistrictData(response?.data)
    // works when district data is received from autofill or autofill is turned off after manually selecting district
    if (districtValues?.length) {
      const autoFillDistricts = []
      for (const ele of response?.data || []) {
        if (districtValues.includes(ele?.id)) {
          autoFillDistricts.push({
            id: ele?.id,
            name: ele?.name,
          })
        }
      }
      setSelectedDistrict(autoFillDistricts)
      setSelectedDistrictFinal(autoFillDistricts)
    }
  }
}

// setting list & map values
const handleDistrictMapData = (data, context, lang, districtValues = []) => {
  const { setMapData, setDistrictData, setSelectedDistrict, setSelectedDistrictFinal } = context
  setMapData(null)
  const district_data = [],
    mapData = [],
    selectedDistricts = []
  data?.map((item) => {
    district_data.push({
      id: item?.features[0]?.properties?.id.toString(),
      name: item?.features[0]?.properties[`name_${lang}`],
    })
    if (districtValues?.length) {
      if (districtValues.includes(item?.features[0]?.properties?.id.toString())) {
        selectedDistricts.push({
          id: item?.features[0]?.properties?.id.toString(),
          name: item?.features[0]?.properties[`name_${lang}`],
        })
      }
    }
    mapData.push({
      ...item?.features[0],
      properties: {
        ...item?.features[0].properties,
        isSelected: 0,
      },
    })
  })
  const finalObjPolygon = {
    type: 'FeatureCollection',
    features: mapData,
    update: false,
  }
  setMapData(finalObjPolygon)
  setDistrictData(district_data)
  // works when district data is received from autofill or autofill is turned off after manually selecting district or live location choosen
  if (districtValues?.length) {
    setSelectedDistrict(selectedDistricts)
    setSelectedDistrictFinal(selectedDistricts)
  }
}

// method to handle the manually changed values after when autofill is on in matchmaker form
export const clearForm = (context, lang) => {
  const {
    manuallyChangedValues,
    setCityId,
    setCityOrDistrictAutofill,
    setAutofillBudgetClass,
    setInitialValue,
    setIsProjectUnitRent,
  } = context
  const data = {
    propertyCategory: manuallyChangedValues?.propertyCategory,
    propertyType: manuallyChangedValues?.propertyType,
    cityId: manuallyChangedValues?.cityId,
    district: manuallyChangedValues?.district
      ? manuallyChangedValues?.district.map((district) => district?.id).join()
      : '',
    fullName:
      manuallyChangedValues?.fullName || (typeof window !== 'undefined' && (localStorage.getItem('firstName') || '')),
    phoneNumberCountryCode: fetchCountryCode() || '+966',
    mobileNo:
      manuallyChangedValues?.mobileNo || (typeof window !== 'undefined' && (localStorage.getItem('phoneNumber') || '')),
    userType: manuallyChangedValues?.userType,
    minPrice: manuallyChangedValues?.minPrice,
    maxPrice: manuallyChangedValues?.maxPrice,
    terms: true,
    isInsideMall: manuallyChangedValues?.isInsideMall,
    bedroomId: manuallyChangedValues?.bedroomId,
    paymentType: manuallyChangedValues?.paymentType,
    listingTypeId:
      manuallyChangedValues?.paymentType == '0' || manuallyChangedValues?.paymentType == '1' ? listingValues[0] : '',
    preApproved: manuallyChangedValues?.preApproved,
  }
  setCityId(data.cityId)
  fetchDistrict(
    data.cityId,
    context,
    lang,
    manuallyChangedValues?.district?.map((item) => item.id),
    false,
  )
  setCityOrDistrictAutofill({ city: false, district: false })
  setAutofillBudgetClass({ max: '' })
  setInitialValue(data)
  setIsProjectUnitRent(false)
}

/*1.)To handle manual values filled by user after toggle get On and removing manually filled field and value from autofilled values
  2.) If user manually change from Buy to Rent and then enable autofill. Form will autofill data based on the currently selected Property Type ie. Rent/Buy
  3.) If user manually changed second level values ie. Purpose_type or Bedroom then consider first level value i.e Main_type also manually changed.*/
export const handleFieldChange = (e, context) => {
  const { autoFilledValues, setAutoFilledValues, manuallyChangedValues, setManuallyChangedValues } = context
  const { name, value, type, checked } = e.target
  if (type === 'checkbox') {
    const tempState = { ...autoFilledValues }
    if (name === 'isInsideMall') {
      setManuallyChangedValues((prevState) => ({
        ...prevState,
        [name]: checked,
      }))
    } else if (name === 'preApproved') {
      setManuallyChangedValues((prevState) => ({
        ...prevState,
        [name]: checked,
        ['paymentType']: '1',
        ['listingTypeId']: listingValues[0],
      }))
    } else if (autoFilledValues[name]?.includes(value)) {
      setManuallyChangedValues((prevState) => ({
        ...prevState,
        [name]: tempState[name].filter((val) => val !== value),
      }))
      delete tempState[name]
      setAutoFilledValues(tempState)
    } else if (autoFilledValues[name]) {
      setManuallyChangedValues((prevState) => {
        const indexExists = prevState[name]?.indexOf(value)
        if (indexExists === -1 || indexExists === undefined) {
          return prevState[name]
            ? {
                ...prevState,
                [name]: [...prevState[name], ...tempState[name], value],
              }
            : {
                ...prevState,
                [name]: [...tempState[name], value],
              }
        }
        const temp = { ...prevState }
        temp[name]?.splice(indexExists, 1)
        return temp
      })
      delete tempState[name]
      setAutoFilledValues(tempState)
    } else {
      setManuallyChangedValues((prevState) => {
        const indexExists = prevState[name]?.indexOf(value)
        if (indexExists === -1 || indexExists === undefined) {
          return prevState[name]
            ? {
                ...prevState,
                [name]: [...prevState[name], value],
              }
            : {
                ...prevState,
                [name]: [value],
              }
        }
        const temp = { ...prevState }
        temp[name]?.splice(indexExists, 1)
        return temp
      })
    }
    if (name === 'propertyType' && 'propertyCategory' in autoFilledValues) {
      setManuallyChangedValues((prevState) => ({
        ...prevState,
        propertyCategory: autoFilledValues['propertyCategory'],
      }))
      delete tempState['propertyCategory']
      setAutoFilledValues(tempState)
    }
    if (name === 'bedroomId' && 'propertyType' in autoFilledValues) {
      setManuallyChangedValues((prevState) => ({
        ...prevState,
        propertyType: autoFilledValues['propertyType'],
      }))
      delete tempState['propertyType']
      setAutoFilledValues(tempState)
    }
  } else {
    if (autoFilledValues[name] === value || manuallyChangedValues[name] === value) {
      return
    }
    const tempState = { ...autoFilledValues }
    if (name === 'propertyCategory' && name in autoFilledValues) {
      delete tempState['propertyType']
      delete tempState[name]
      setAutoFilledValues(tempState)
      if ('propertyType' in manuallyChangedValues) {
        const temporaryState = { ...manuallyChangedValues }
        delete temporaryState['propertyType']
        setManuallyChangedValues(temporaryState)
      }
    }
    if (name === 'propertyCategory' && name in manuallyChangedValues) {
      const temp = { ...manuallyChangedValues }
      delete temp['propertyType']
      setManuallyChangedValues(temp)
    }
    if (name === 'cityId' && name in autoFilledValues) {
      delete tempState['district']
      setAutoFilledValues(tempState)
    }
    if (name === 'paymentType') {
      delete tempState['listingTypeId']
      setAutoFilledValues(tempState)
    }
    delete tempState[name]
    setAutoFilledValues(tempState)
    setManuallyChangedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
}

export const handleToggle = (context, styles, lang, t, draftData) => {
  const { eventAutoFillData, isToggled, manuallyChangedValues, setIsToggled, userId, setIsValueInitialized, whatsappAbTesting } = context
  const eventData = {
    user_id: userId,
    login_status: localStorage.getItem('authToken') ? true : false,
    pageType: eventAutoFillData?.pageType || '',
    source: eventAutoFillData?.source || '',
    AB_Test_Show_int_Variant: eventAutoFillData?.abVarient,
  }
  if (!isToggled) {
    // Call the function to set default values here
    setIsValueInitialized(false)
    const autofillData = parsedData(t, fetchCountryCode(), lang, manuallyChangedValues?.listingTypeId, draftData)
    objectRemoveEmptyData(autofillData)
    eventData['auto_filled_num'] = Object.entries(autofillData).length - 4
    setintialvaluehandler(manuallyChangedValues?.listingTypeId, styles, context, lang, t, draftData, autofillData)
  } else {
    // Clear the form here
    clearForm(context, lang)
  }
  objectRemoveEmptyData(eventData)

  // executes when autofill is turned ON/OFF on button click
  isToggled !== null &&
    newMatchmakerAnalytics(
      lang,
      pageType?.SYR,
      eventData,
      !isToggled ? matchmakerEvents?.FORM_Events?.SYR_autofill_ON : matchmakerEvents?.FORM_Events?.SYR_autofill_OFF,
      whatsappAbTesting
    )
  setIsToggled(!isToggled)
}

//Handling form radio and checkbox onclick
export const buttonOnClickOrOnChangeHandler = (e, handleChange, name, submitCount, errors, context) => {
  const { isToggled } = context
  handleChange && handleChange(e)
  if (isToggled) {
    handleFieldChange(e, context)
  }
  clearError(name, submitCount, errors)
}

/**
 * To send analytics on form submit
 * @param id - requirement_id
 * @param API_response - Yes/No with api error message based on the status of the API
 * @param auto_filled_clicked_ON - True/false based on the status of autofilled toggle
 * @param Page_sub_type - Page Type from where user get redirect to Matchmaker form
 * @param auto_filled_num - No. of fields which get autofilled when user switch on the smart autofill button
 * @param overwrite_num - Number of fields which are overwrite after the auto filling
 * @param fields_overwrite - True/false based on the status Whether user overwrite atleast one field out of any autofill fields.
 */
export const submitAnalytics = (context, eventName, id, Api_response, lang, first_req=null, data = null) => {
  const {
    selectedDistrictFinal,
    manuallyChangedValues,
    submittedData,
    isToggled,
    eventAutoFillData,
    userId,
    isNewUser,
    isWasaltBusinessView,
    whatsappAbTesting
  } = context
  const values = data || submittedData || {}
  values.district = selectedDistrictFinal.length ? selectedDistrictFinal.map((district) => district?.id).join() : ''
  if (id) values.requirementId = id
  if (Api_response) values.Api_response = Api_response
  values.user_id = isWasaltBusinessView?.userId || userId
  values.auto_filled_clicked = isToggled
  values.Page_sub_type = eventAutoFillData.pageType
  values.source = eventAutoFillData.source
  values.auto_filled_num = isToggled ? eventAutoFillData.autofillcounter : 0
  let overWriteCounter = Object.entries(manuallyChangedValues).length
  if ('maxPrice' in manuallyChangedValues) {
    overWriteCounter -= 1
  }
  if (isNewUser) {
    overWriteCounter -= 2
  }
  if ('listingTypeId' in manuallyChangedValues) {
    overWriteCounter -= 1
  }
  values.overwrite_num = overWriteCounter
  values.fields_overwrite = overWriteCounter > 0
  values.showint_variant = eventAutoFillData?.abVarient
  values.abVarient_WA = getWhatsappAbTestVarient(whatsappAbTesting)
  values.first_req = first_req
  syrAnalytics({ values, window, lang, eventName })
}

//event on Skip Prompt on match maker
export const promptEventHandler = (lang, event_nm, eventAutoFillData, isWasaltBusinessView) => {
  const eventData = {
    source: eventAutoFillData?.source,
    user_id: isWasaltBusinessView?.user_id,
    AB_Test_Show_int_Variant: eventAutoFillData?.abVarient,
    Login_WA_MM : eventAutoFillData?.whatsappAbTestvarient
  }
  objectRemoveEmptyData(eventData)
  newMatchmakerAnalytics(lang, eventAutoFillData?.pageType, eventData, event_nm)
}

/**
 * To send analytics on form submit
 * @param operation - push/pop based on which event array will be manipulated
 * @param selectedFrom - to identify from where district is selected
 */
export const districtSelectedFrom = (operation, context, selectedFrom = '') => {
  const { selectedDistrictEvent, setSelectedDistrictEvent } = context

  if (operation === 'push') {
    setSelectedDistrictEvent((prev) => [...prev, selectedFrom])
  }
  if (operation === 'pop') {
    const temp = [...selectedDistrictEvent]
    const index = temp.lastIndexOf(selectedFrom)
    temp.splice(index, 1)
    setSelectedDistrictEvent(temp)
  }
}
export const importTNC = async (context) => {
  const { TermsConditions, setTermsConditions } = context
  if (TermsConditions === null) {
    const Component = await import(
      /* webpackChunkName: "TermsConditions" */ '@/components/StaticPages/TermsConditions'
    )
    setTermsConditions(Component)
  }
}
export const importPromptMsg = async (context) => {
  const { PromptMsg, setPromptMsg } = context
  if (PromptMsg === null) {
    const Component = await import(/* webpackChunkName: "PromptMsg" */ '@/components/ShareYourRequirement/PromptMsg')
    setPromptMsg(Component)
  }
}
export const importYourPopupDetails = async (context) => {
  const { YourDetails, setYourDetails } = context
  if (YourDetails === null) {
    const Component = await import(
      /* webpackChunkName: "YourDetails" */ '@/components/ShareYourRequirement/YourDetails'
    )
    setYourDetails(Component)
  }
}
export const importVerifyOtpScreen = async (context) => {
  const { VerifyOtpScreen, setVerifyOtpScreen } = context
  if (VerifyOtpScreen === null) {
    const Component = await import(
      /* webpackChunkName: "VerifyOtpScreen" */ '@/components/UserProfile/VerifyOtp/VerifyOtpScreen'
    )
    setVerifyOtpScreen(Component)
  }
}
export const importDistrictMapDetails = async (context) => {
  const { DistrictMapDetails, setDistrictMapDetails } = context
  if (DistrictMapDetails === null) {
    const Component = await import(/* webpackChunkName: "DistrictMapDetails" */ '@/components/UserProfile/DistrictMap')
    setDistrictMapDetails(Component)
  }
}

export const lowerCase = (str)=> str ? `${str?.charAt(0).toLowerCase()}${str?.slice(1)}` : '';