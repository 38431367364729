import { getIdByName, isUserLoggedIn, pushDataLayerEventHandler } from "@/utils/utils"
import { commonApi, getRequirementsApi } from "./MMDeeplink/services"
import { shareFormSchema, shareFormSchemaProfile } from "./Matchmaker.schema"
import { bankDataConst, listingValues, propertyValues, showBedroomPropertyTypeSlug } from "@/utils/constant"
import { addRequirement, checkContactExistence, saveRequirementDraft } from "services/postLiteService"
import { districtSelectedFrom, submitAnalytics } from "./MatchMakerMethods"
import { eventData as eventInfo, matchmakerEvents, pageType as currentPage, dispatchConst, bedroomValue, viewMode, SYRConstant } from "./MatchmakerConstant"
import { cacheClear, getSuccessPageUrl } from "./MatchmakerSuccessPageMethods"
import uniqueIdGenerator from '@/utils/generateUniqueId'

export const getApi = async (baseURL,apiUrl,parameters,lang) => {
    try {
      const response = await fetch(`${baseURL}${apiUrl}?${parameters}`, {
        method: 'GET',
        headers: {
          locale: lang,
          'Content-Type': 'application/json',
          'x-request-id': uniqueIdGenerator.getId()
        },
      })
      if(!response.ok){
        throw new Error(`Failed to fetch ${apiUrl}`)
      }
      return await response.json()
    }
    catch (error) {
      throw new Error(`Error occurred: ${error}`);
    }
  }

export const setCookieOnLogin = (token, refreshToken, authStoredDate, userID, cookie) => {
    const domain = location.host
    const cookieOptions = {
      path: '/',
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)), // 1 moth expiry added to cookie
    }
    if (domain.split('.').length > 1) {
      const domainParts = domain.split('.')
      domainParts.shift()
      cookieOptions.domain = '.' + domainParts.join('.')
    }
    cookie.set('authToken', token, cookieOptions)
    cookie.set('userId', userID, cookieOptions)
    cookie.set('refreshToken', refreshToken, cookieOptions)
    cookie.set('authStoredDate', authStoredDate, cookieOptions)
    if (domain.match(/[a-z]/i) == null) {
      delete cookieOptions.domain
      cookie.set('authToken', token, cookieOptions)
      cookie.set('userId', userID, cookieOptions)
      cookie.set('refreshToken', refreshToken, cookieOptions)
      cookie.set('authStoredDate', authStoredDate, cookieOptions)
    } else if (domain.split('.').length > 1 && (cookie.get('authToken') == null || cookie.get('authToken') != token)) {
      cookieOptions.domain = '.' + location.host
      cookie.set('authToken', token, cookieOptions)
      cookie.set('userId', userID, cookieOptions)
      cookie.set('refreshToken', refreshToken, cookieOptions)
      cookie.set('authStoredDate', authStoredDate, cookieOptions)
    }
}

export const citiData = (lang, city, dispatch)=>{
  const urlAPi = `${process.env.NEXT_PUBLIC_API_URL_V1}cities?popular=true`;
  const method = 'POST'
  const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'locale' : lang,
        'x-request-id': uniqueIdGenerator.getId()
      }
  const errorLabel= 'Error on Cities'
  commonApi(urlAPi,method,headers,errorLabel,(res)=>{
    const {status,data} = res
    if (status) {
      const { allCities, popularCities} = data
      data.allCities = data?.allCities ? [...allCities, ...popularCities] : {}
      dispatch({type: 'SET_CITY_ID', payload: getIdByName(data,city)})
      }
  })
}

export const matchmakerFormValidation = (props) => {
  const {yourDetailPopUp, selectedDistrictFinal, t, isWasaltBusinessView, bankData, isName_Available, isPreApproved,propertyType} = props;
  const mortgageCheck = propertyType === '419' ? true : false
  if(yourDetailPopUp){
    return shareFormSchemaProfile(t)
  } 
  return shareFormSchema(selectedDistrictFinal, t, isWasaltBusinessView, bankData?.data?.mortgageEnabled, isName_Available, isPreApproved,mortgageCheck)
}

export const getBankData = (data, type, bankSlug=null, lang='en') => {
  if(bankSlug){
    const bankData = data?.find((item) => item?.slug === bankSlug);
      switch(type){
        case SYRConstant.BANK_LOGO:
          return lang === 'ar' ? bankData?.arabicLogo : bankData?.logo;
        case SYRConstant.BANK_NAME:
          return  bankData?.name;
        case SYRConstant.BANK_SLUG:
            return  bankData?.slug;
        case SYRConstant.BANK_DATA:
          return bankData?.employMentType;
      }
  }
}

export const getWhatsappAbTesting =(isEnabled,uCookies)=>{
  if(isEnabled) {
    return uCookies?.cookies?.AB_cookie1 === 'A' ? false : true
  }
  return false
}

export const newMatchmakerAnalytics = (lang, pageType, data, eventName) => {
  const eventData = {...data }
  pushDataLayerEventHandler(window, lang, eventName, pageType, eventData)
}

export const getBankDetails = async (lang, dispatch=null) => {
  fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}/requirement-manager/banks`, {
    method: 'GET',
    headers: {
      locale: lang,
      Cookie: `i18next=${lang}`,
    },
  })
    .then((response) => response.json())
    .then((data) => dispatch({ type: 'SET_BANK_DETAILS', payload: data }))
    .catch((error) => console.error(error))
}

export const onSelection = (event, matchmakerContext, setFieldValue, lang) => {
  const {selectedDistrict, setSelectedDistrict, manageStateOnDistrictDeletion, setDistrictLimit } = matchmakerContext;
  
  if (event?.features?.length) {
    const districtNameList = [...selectedDistrict]
    if (!districtNameList.some((ele) => ele.id == event?.features[0]?.properties?.districtId)) {
      if (districtNameList?.length < 5) {
        const item = {
          id: event?.features[0]?.properties.districtId.toString(),
          name: event?.features[0]?.properties[`name_${lang}`],
        }
        districtNameList.push(item)
        districtSelectedFrom('push', matchmakerContext, viewMode.mapView)
        setSelectedDistrict(districtNameList)
        manageStateOnDistrictDeletion(setFieldValue, districtNameList)
        setDistrictLimit(false)
      } else {
        setDistrictLimit(true)
      }
    } else {
      const index = districtNameList.findIndex(
        (district) => district?.id == event?.features[0]?.properties?.districtId,
      )
      districtNameList.splice(index, 1)
      districtSelectedFrom('pop', matchmakerContext, viewMode.mapView)
      setSelectedDistrict(districtNameList)
      manageStateOnDistrictDeletion(setFieldValue, districtNameList)
      setDistrictLimit(false)
    }
  }
}

export const districtHandler = (e, item, matchmakerContext, setFieldValue) => {
  const {selectedDistrict, setSelectedDistrict, manageStateOnDistrictDeletion, setDistrictLimit } = matchmakerContext;
  const districtNameList = [...selectedDistrict]
  
  if (e.target.checked) {
    if (districtNameList?.length < 5) {
      districtSelectedFrom('push', matchmakerContext, viewMode.listView)
      districtNameList.push(item)
      setSelectedDistrict(districtNameList)
      manageStateOnDistrictDeletion(setFieldValue, districtNameList)
      setDistrictLimit(false)
    } else {
      setDistrictLimit(true)
    }
  } else {
    const index = districtNameList.findIndex((district) => district?.id == item?.id)
    districtNameList.splice(index, 1)
    districtSelectedFrom('pop', matchmakerContext, viewMode?.listView)
    setSelectedDistrict(districtNameList)
    setDistrictLimit(false)
    manageStateOnDistrictDeletion(setFieldValue, districtNameList)
  }
}

const checkContactExistenceAndGoToOtp = async (values, locale, setFormValues, setVerifyOtp, setOtpPhoneNumber, setUserId, showErrorToast) => {
  const { fullName, mobileNo, phoneNumberCountryCode } = values
  const data = {
    fullName,
    phoneNumber: mobileNo,
    phoneNumberCountryCode: phoneNumberCountryCode || '+966',
  }

  const response = await checkContactExistence(data, locale)

  if (response?.status || response?.status == 'true') {
    setFormValues(values)
    setVerifyOtp(true)
    setOtpPhoneNumber(mobileNo)
    setUserId(response?.data?.userId)
  } else {
    showErrorToast(response?.message)
  }
}

export const redirectToSuccessPage = async (values, bankData, router, lang, matchmakerContext, first_req) => {
  const {setIsSubmitLoading, selectedDistrictFinal, isWasaltBusinessView, isMobile, eventAutoFillData } = matchmakerContext;
  try {
    const data = getAddRequirementData(values, bankData, selectedDistrictFinal, isMobile, router, eventAutoFillData, isWasaltBusinessView)
    const response = await addRequirement(data, lang)
    if (response?.status) {
      if (typeof window !== 'undefined' && sessionStorage.getItem('PageRefresh')) {
        sessionStorage.removeItem('PageRefresh')
      }
      submitAnalytics(matchmakerContext, currentPage.SYR_FORM_SUBMIT, response?.data?.requirementId, 'Yes', lang, first_req)
      if (isWasaltBusinessView) {
        window.location.href = `https://www.wasalt.sa?status=true&id=${response?.data?.requirementId}`
      } else {
        setTimeout(() => {
            router.push(getSuccessPageUrl(response?.data?.requirementId, eventAutoFillData?.source))
        }, 1000)
      }
    } else {
      // passing Api error massage to the google analytics
      if (isWasaltBusinessView) {
        window.location.href = `https://www.wasalt.sa?status=false`
      }
      submitAnalytics(
        matchmakerContext,
        currentPage.SYR_FORM_SUBMIT,
        response?.data?.requirementId,
        `No, error: ${response.message}`,
        lang,
        first_req
      )
    }  
  }catch (error) {
    setIsSubmitLoading(false)
  }
}

//for event on clicking submit
export const onSubmitClick = ({values, locale, router, lang, showErrorToast, matchmakerContext, bankData, setShowLimitingPopUp}) => {
  const {isNewUser, isMobile, setFormValues, setVerifyOtp, setOtpPhoneNumber, setUserId, setPushStateCount, pushStateCount, scrollPosition, yourDetailPopUp, setIsPopupOpen } = matchmakerContext;
  if (!isNewUser || isUserLoggedIn()) {
    getRequirements(lang, ()=>{
      setIsPopupOpen(true)
      setShowLimitingPopUp(true)}, (first_req)=>redirectToSuccessPage(values, bankData, router, lang, matchmakerContext, first_req), router?.query?.authToken)
  } else {
    checkContactExistenceAndGoToOtp(values, locale, setFormValues, setVerifyOtp, setOtpPhoneNumber, setUserId, showErrorToast)
    if (isMobile) {
      setPushStateCount(pushStateCount + 1)
      // Modify the URL and add a new history entry using window.history.pushState
      window.history.pushState(
        { scrollPosition },
        '',
        router.locale == 'ar' ? router.asPath : `/${router.locale}${router.asPath}`,
      )
    }
  }
  submitAnalytics(matchmakerContext, 'SYR_Form_submit_click', null, null, lang, null, values)
  yourDetailPopUp && newMatchmakerAnalytics(lang, currentPage?.LOGIN_SCREEN, { login_type : eventInfo?.LOGIN_TYPE }, matchmakerEvents?.newLoginEvent?.LOGIN_CONTINUE) 
}

export const saveDraft = async ({values, selectedDistrictFinal, bankData, eventAutoFillData, isNewUser, lang, setHasValuesChanged, router, isMobile}) => {
  const {
    propertyCategory,
    propertyType,
    listingTypeId,
    cityId,
    bedroomId,
    userType,
    isInsideMall,
    minPrice,
    maxPrice,
    fullName,
    paymentType,
    preApproved
  } = values

  const selectedDistrictsTemp = selectedDistrictFinal?.map((district) => district?.id)
  /**
   * To send vaules on form submit
   * @param Entry_point - Page Type from where user get redirect to Matchmaker form
   * @param Source - return wesite/m-site based on platform from which we submit the matchmaker form
   */
  const listingTypeIdValue = listingTypeId == listingValues[0] ? SYRConstant.REQ_BUY : SYRConstant.REQ_RENT
  const paymentTypeValue = paymentType == '0' ? SYRConstant.REQ_CASH : SYRConstant.REQ_MORTGAGE
  const isReqBankAvaiable = listingTypeId == listingValues[0] ? getBankData(bankData?.data?.req_banks, SYRConstant.BANK_SLUG, bankDataConst.MORTGAGE_BANK_SLUG): getBankData(bankData?.data?.req_banks, SYRConstant.BANK_SLUG, bankDataConst.RIZE_BANK_SLUG)

  const data = {
    mainType: propertyCategory,
    purposeType: propertyType,
    listingType: listingTypeId ? listingTypeIdValue : '',
    cityId,
    districtId: selectedDistrictsTemp,
    iam: userType,
    purchaseType: (bankData?.data?.mortgageEnabled && paymentType) ? paymentTypeValue : '',
    bedrooms: getBedroom(propertyType,bedroomId),
    minPrice: minPrice || '',
    maxPrice: maxPrice || '' ,
    fullName: localStorage?.getItem('firstName') || fullName,
    phoneNumber: localStorage?.getItem('phoneNumber') || '',
    isInsideMall: propertyType?.some((value) => propertyValues?.includes(value)) ? isInsideMall : false,
    reqBanks : preApproved ? isReqBankAvaiable : '',
    entryPoint: eventAutoFillData?.pageType,
    source: getSource(router, isMobile)
  }
  !isNewUser && saveRequirementDraft(data, lang, setHasValuesChanged);
}
export const getWhatsappAbTestVarient = (whatsappAbTesting) => whatsappAbTesting ? 'B' : 'A'

export const removeLocalStorageParams = (params) => {
  if (params.length === 0) return null;
  params.map((key) => localStorage.removeItem(key))
}

export const priceConverter = (price, t) => {
  if (price != undefined && price != null) {
    if (price < 999) {
      return price
    } else if (price > 999 && price < 1000000) {
      if (price == 100001) {
        return 100 + t('shareRequirement.K') + '+'
      }
      return price / 1000 + t('shareRequirement.K')
    } else if (price >= 1000000 && price < 10000001) {
      return price / 1000000 + t('shareRequirement.M')
    } else if (price == 10000001) {
      return 10 + t('shareRequirement.M') + '+'
    }
  }
}

export const fetchData = async (router, lang, dispatch, onPaymentPage=false) => {
  let responseData = null
  try {
    if ((router?.query?.req_id || router?.query?.draft_id ) && !router?.query?.requirementId ) {
      responseData = await getApi(
      process.env.NEXT_PUBLIC_API_URL_V3,
      '/resolve-deeplinking-url',
      `url=${encodeURIComponent(window.location.href)}`,
      lang
    );
    if (!responseData?.data?.parameters?.id ) {
      router.push('/user/myrequirements?source=mmlandingpage')
    }
    if (responseData?.data?.parameters?.id && isUserLoggedIn()) {
      cacheClear()
    }    
    }
    const req_idLocal = router?.query?.requirementId ?? responseData?.data?.parameters?.id;
    if (req_idLocal) {
      await getRequirementsApi(req_idLocal, dispatch, router, lang, onPaymentPage, router?.query?.source, true);
    }
    dispatch({ type: dispatchConst.SET_REQUIREMENT_ID, payload: req_idLocal });
  } catch (error) {
    throw new Error(`Error occurred : ${error}`);
  }
};

const getBedroom = (propertyType,bedroomId) => {
  if(propertyType?.some((slug) => [...showBedroomPropertyTypeSlug['residential'], ...showBedroomPropertyTypeSlug['commercial']].includes(slug),)) {
       return (bedroomId?.map((id) => {
          if (id == '9+') return '10'
          else if (id == bedroomValue.EN_BEDROOM_ID || id == bedroomValue.AR_BEDROOM_ID) return '0'
          return id
        }))
      }
      return []
}

const getSource = (router, isMobile) => {
  if(isMobile) {
    return router?.query?.source ? router?.query?.source : 'msite'
  } 
  return 'website'
}

export const getRequirements = async (lang, setShowLimitingPopUp, submitRequirement, authToken=null) => {
  try{
  const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/get-requirements`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      locale: lang,
      Authorization: authToken ?? localStorage.getItem('authToken'),
      'x-request-id': uniqueIdGenerator.getId()
    },
  })
  if(!response.ok){
    throw new Error(`Failed to fetch get-requirements, HTTP error Status:${res.status}`)
  }
  const res = await response.json()
  if (res?.status) {
    if(res?.data?.nonPremium > 0){
      setShowLimitingPopUp()
    } else {
    submitRequirement(res?.data?.count<1)
    }
  } 
  }
  catch(error){
    throw new Error(`Error occurred in getRequirementsCount : ${error}`)
  }
}

export const onSkipandCancelButtonMMprompt = (eventAutoFillData, pushStateCount, backButtonType ,isMobile, router, limitingMsg=false) => {
  if (sessionStorage.getItem('PageRefresh')) {
    sessionStorage.removeItem('PageRefresh')
  }
  if (sessionStorage.getItem('abVarient')) {
    sessionStorage.removeItem('abVarient')
  }
  if (
    eventAutoFillData?.source == 'Google' ||
    eventAutoFillData?.source == 'Direct' ||
    eventAutoFillData?.source == 'profile_req_your_prop_clicked' ||
    !router?.query ||
    backButtonType === 'wasalt_logo'
    || router.query.draft_id ||
    ((eventAutoFillData?.source == 'SRP_banner' || eventAutoFillData?.source == 'LRP_banner' || eventAutoFillData?.source == 'NRP_banner') && !isMobile)
  ) {
    setTimeout(() => {
      router.push('/')
    }, 200)
  } else if(limitingMsg) {
    window.history.go(-pushStateCount-1)
  } else {
    //navigate to back in window history
    window.history.go(-pushStateCount)
  }
}

export const postApi = async (baseURL,apiUrl,lang, bodyData=null) => {
  try {
    const response = await fetch(`${baseURL}${apiUrl}`, {
      method: 'POST',
      headers: {
        locale: lang,
        'Content-Type': 'application/json',
        'x-request-id': uniqueIdGenerator.getId(),
        Authorization: localStorage.getItem('authToken'),
      },
      body: bodyData
    })
    if(!response.ok){
      throw new Error(`Failed to fetch ${apiUrl}`)
    }
    return await response.json()
  }
  catch (error) {
    throw new Error(`Error occurred in getApi:: ${error}`);
}
}

const getAddRequirementData = (values, bankData, selectedDistrictFinal, isMobile, router, eventAutoFillData, isWasaltBusinessView) => {
  const {
    propertyCategory,
    propertyType,
    listingTypeId,
    cityId,
    bedroomId,
    userType,
    isInsideMall,
    minPrice,
    maxPrice,
    fullName,
    paymentType,
    preApproved,
    employmentMode,
    monthlySalary,
    age
  } = values
  const selectedDistrictsTemp = selectedDistrictFinal?.map((district) => district?.id)
  const paymentValue = paymentType == '0' ? SYRConstant.REQ_CASH : SYRConstant.REQ_MORTGAGE
  const listingTypeIdValue = listingTypeId === listingValues[0] 

  const isReqBankAvaiable = listingTypeIdValue ? getBankData(bankData?.data?.req_banks, SYRConstant.BANK_SLUG, bankDataConst.MORTGAGE_BANK_SLUG): getBankData(bankData?.data?.req_banks, SYRConstant.BANK_SLUG, bankDataConst.RIZE_BANK_SLUG);
  /**
   * To send vaules on form submit
   * @param Entry_point - Page Type from where user get redirect to Matchmaker form
   * @param Source - return wesite/m-site based on platform from which we submit the matchmaker form
   */
  return {
    mainType: propertyCategory,
    purposeType: propertyType,
    listingType: listingTypeIdValue ? SYRConstant.REQ_BUY : SYRConstant.REQ_RENT,
    cityId,
    districtId: selectedDistrictsTemp,
    iam: isWasaltBusinessView ? 'broker' : userType,
    purchaseType: (bankData?.data?.mortgageEnabled && listingTypeIdValue) ? paymentValue : '',
    bedrooms: getBedroom(propertyType,bedroomId),
    minPrice,
    maxPrice,
    fullName: isWasaltBusinessView ? router?.query?.fullName : fullName || localStorage?.getItem('firstName'),
    source: getSource(router, isMobile),
    entryPoint: eventAutoFillData?.pageType,
    isInsideMall: propertyType?.some((value) => propertyValues?.includes(value)) ? isInsideMall : false,
    reqBanks : preApproved ? isReqBankAvaiable : '',
    employmentMode: preApproved ? employmentMode : '',
    monthlySalary: preApproved ? monthlySalary : 0,
    age: preApproved ? age : 0,
    authToken: router?.query?.authToken ?? null
  }
}