import { SYRConstant, getLanguage } from '@/components/ShareYourRequirement/MatchmakerConstant'
import { matchMakerDefaultAutofill, pageName, listingValues, price } from '@/utils/constant'
import uniqueIdGenerator from '@/utils/generateUniqueId'

// restricting homepage search
export const parsedData = (t, countryCodeTemp, lang, propertyFor = null, draftData=null) => {
  let data
  if (typeof window !== 'undefined') {
    data = JSON.parse(localStorage.getItem('routerParams'))
  }

  // only work for matchmaker retrageting
  if(data && ['wa_abandoned_first_retargeting', 'wa_abandoned_second_retargeting'].includes(data?.source) && !('type' in data)) {
    let userType = ''
    let purchaseType = ''
    if (draftData?.iam === 'Property Seeker') {
      userType = 'seeker'
    } else if (draftData?.iam) {
      userType = draftData?.iam?.toLowerCase().trim()
    }
    if(draftData?.purchaseType === "req-mortgage") {
      purchaseType = '1'
    } else if (draftData?.purchaseType === "req-cash") {
      purchaseType = '0'
    } 
    return {
        propertyCategory: draftData?.lookingFor || '',
        propertyType: draftData?.propertyType?.length ? parsePropertyType(draftData?.propertyType.split(',')) : '' ,
        listingTypeId: listingValues[0],  //only for buy
        cityId:  draftData?.city || '',
        district: draftData?.districtIds?.length > 0 ? draftData?.districtIds.join() : [].join(),
        bedroomId: draftData?.bedrooms?.length ? checkBedroom(t, draftData?.bedrooms?.split(getArabicCommo(lang))) : [],
        minPrice: draftData?.min ,
        maxPrice: draftData?.max ,
        fullName: typeof window !== 'undefined' && (localStorage.getItem('firstName') || ''),
        phoneNumberCountryCode: countryCodeTemp,
        mobileNo: typeof window !== 'undefined' && (localStorage.getItem('phoneNumber') || ''),
        userType,
        terms: true,
        paymentType: '1',
        preApproved: draftData?.preApproved || false,
        monthlySalary: draftData?.preApproved ? draftData?.monthlySalary : '',
        employmentMode: draftData?.preApproved ? draftData?.employmentMode : '',
        age: draftData?.age ? draftData?.age : ''
    }  
  }


  // work for all except pageName = ['SRP', 'LRP', 'NRP', 'PDP']
  if ((data && !('type' in data) && !pageName.includes(data?.pageType) && data?.banner_type !== 'savedPropertyBanner') || !data ) {
    data = matchMakerDefaultAutofill[propertyFor === null || propertyFor === listingValues[0] ? SYRConstant.BUY : SYRConstant.RENT]
  }

  const dataPropertyFor = listingValues[0] //only for buy
  const listingType = propertyFor || dataPropertyFor

  // call when switch buy-rent pills return preapproved , monthly salary and employee mode
  const getDataForProperty = (fieldName, defaultValue)=> data?.propertyFor === propertyFor ? fieldName : defaultValue
  
  const preApprovedValue = getDataForProperty(data?.preApproved, false);
  const monthlySalaryValue = getDataForProperty(data?.monthlySalary, '');
  const employmentModeValue = getDataForProperty(data?.employmentMode, '');
  const ageValue = getDataForProperty(data?.age, '');

  return {
    propertyCategory: data?.propertyCategory,
    propertyType: data?.propertyTypeData?.length
      ? getPropertyTypeDataValue(data)
      : setDefaultPropertyType(listingType, data?.pageType, data?.propertyCategory),
    listingTypeId: dataPropertyFor,
    cityId: data?.cityId,
    district: data && 'districtId' in data ? data?.districtId?.split(',').join() : [].join(),
    bedroomId: data?.bedroomsCount?.length ? checkBedroom(t, data?.bedroomsCount?.split(',')) : setDefaultBedroom(data, listingType),
    minPrice:
      priceHandler(data, propertyFor, dataPropertyFor, SYRConstant.MIN, listingType === listingValues[0] ? SYRConstant.BUY : SYRConstant.RENT),
    maxPrice:
      priceHandler(data, propertyFor, dataPropertyFor, SYRConstant.MAX, listingType === listingValues[0] ? SYRConstant.BUY : SYRConstant.RENT),
    fullName: data?.fullName ?? (typeof window !== 'undefined' && (localStorage.getItem('firstName') || '')),
    phoneNumberCountryCode: countryCodeTemp,
    mobileNo: typeof window !== 'undefined' && (localStorage.getItem('phoneNumber') || ''),
    userType: 'seeker',
    terms: true,
    paymentType: '1',
    preApproved: propertyFor ? preApprovedValue : data?.preApproved,
    monthlySalary: propertyFor ? monthlySalaryValue : data?.monthlySalary,
    employmentMode: propertyFor ? employmentModeValue : data?.employmentMode,
    age: propertyFor ? ageValue : data?.age
  }
}

// returns default listing type value based on pages
const setDefaultPropertyType = (propertyFor, pageType, propertyCategory) => {
  const listingTypeValues = {
    commercial : {'419' : ['commercial-land'], '420':['showroom']},
    residential : {'419' : ['villa'], '420':['apartment']}
  }
  const resultPages = ['SRP', 'LRP', 'NRP', 'Home']
  if (resultPages.includes(pageType)) {
    return listingTypeValues[propertyCategory][propertyFor]
  }
  return []
}

// parses the bedroom count data
const checkBedroom = (t, bedroomsCount) =>
  bedroomsCount.map((bedroom) => {
    if (bedroom === '0') {
      return t('shareRequirement.Studio')
    } else if (bedroom === '10' || bedroom === '+9') {
      return '9+'
    }
    return bedroom
  })

// return default bedroom count data
const setDefaultBedroom = (data, listingType) => {
  if (!data?.propertyTypeData?.length || data?.propertyTypeData?.includes('villa-townhouse')) {
    return listingType === listingValues[0] ?  SYRConstant.BUY_DEFAULT_BEDROOM : SYRConstant.RENT_DEFAULT_BEDROOM
  }
  return []
}

// parses the property type based on page
export const parsePropertyType = (value, propertyCategory = 'residential') => {
  if (['residential','Residential'].includes(propertyCategory)) {
    return value.map((type) => {
      if (type === 'villa-townhouse') {
        return 'villa';
      } else if (type === 'building') {
        return 'residential-building';
      } else if (type === 'farm-house') {
        return 'farm';
      }
      return type;
    });
  }
    return value.map((type) => {
      if (type === 'building-1') {
        return 'commercial-building';
      } else if (type === 'showroom-1') {
        return 'showroom';
      } else if (type === 'land') {
        return 'commercial-land';
      }
      return type;
    });
};


//changes propertyType according to srp
export const parsePropertyTypeForm = (value) =>
  value.map((type) => {
    if (type === 'villa') {
      return 'villa-townhouse'
    } else if (type === 'residential-building') {
      return 'building'
    } else if (type === 'farm') {
      return 'farm-house'
    } else if (type === 'land') {
      return 'residential-land'
    } else if (type === 'commercial-building') {
      return 'building-1'
    } else if (type === 'showroom') {
      return 'showroom-1'
    } else if (type === 'commercial-land') {
      return 'land'
    }
    return type
  })

// price handler
const priceHandler = (data, propertyFor, dataPropertyFor, type, defaultVal) => {
  let amount = ''
  amount = getAmount(data, propertyFor, dataPropertyFor)
  if (amount) {
    for (let i = price[defaultVal].length - 1; i >= 0; i--) {
      if (amount > price[defaultVal][i]?.min) {
        if (type === SYRConstant.MAX) {
          return price[defaultVal][i]?.max
        }
        if (type === SYRConstant.MIN) {
          return price[defaultVal][i]?.min
        }
      }
    }
  }
  return price[defaultVal][3][type]
}

const getAmount = (data, propertyFor, dataPropertyFor) => {
  if (data && (!propertyFor || propertyFor === dataPropertyFor)) {
    if ('max_price' in data) {
      return data['max_price']
    } else if ('priceRange' in data) {
      return data?.priceRange[0][SYRConstant.MAX]
    }
  }
}

// API for kml data
export const kml_data = async (lang, cityId, isLoadCrmKml = false) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/maps/kml/true/polygon/56/${cityId}/-/district/map_view_mobile/${isLoadCrmKml}`, {
      method: 'GET',
      headers: {
        locale: lang,
        'Content-Type': 'application/json',
        'x-request-id': uniqueIdGenerator.getId()
      },
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

// API for fetching current location details
export const checkForCurrentLocation = async (reqHeaders, reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/location/district-by-current-location`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqBody),
    })
    return await response.json()
  } catch (error) {
    return error?.response
  }
}

export const getPackagePurchage = async (locale,id) => {
  try{
    const getURL = `${process.env.NEXT_PUBLIC_TRAWLER_URL}user/action/${id}`
    const response = await fetch(`${encodeURI(getURL)}`, {
      method: 'GET',
      headers: {
        locale,
        Cookie: `i18next=${locale}`,
        'Content-Type': 'application/json',
      }
    });
    const res = await response.json();
    if(res.status){
      return res
    }
  }catch (error) {
    console.error("Error occurred:", error)
  }
}


export const getPropertyData = async (propertyFor, brokerId, locale, pageNo=0,callback=null) => {
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL_V3}/properties/filter`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          locale,
          Cookie: `i18next=${locale}`,
        },
        body: JSON.stringify({
          "includeProperties": true,
          propertyFor,
          "userId": brokerId,
          "pageSize": 16,
          "page": pageNo,
        }),
      },
    )
    const response = await res.json()
    if (response?.status) {
      callback && callback(response?.data);
      return response?.data;
    }
   } catch (error) {
      console.error("Error occurred:", error)
   }
}

export  const getCompanyDetails = async (brokerId, locale) => {
  try{
    const getURL = `${process.env.NEXT_PUBLIC_GATEWAY_URL}broker-company-details/${brokerId}`
    const response = await fetch(`${encodeURI(getURL)}`, {
      method: 'GET',
          headers: {
            locale,
            Cookie: `i18next=${locale}`
          },
    });
    const res = await response.json();
    if(res.status){
      return res?.data
    }
  }catch (error) {
    console.error("Error occurred:", error)
  }
}

const getPropertyTypeDataValue = (data) => Array.isArray(data?.propertyTypeData) ? parsePropertyType(data?.propertyTypeData, data?.propertyCategory) : parsePropertyType(data?.propertyTypeData.split(','), data?.propertyCategory) 

const getArabicCommo = (lang) =>  lang==getLanguage.AR ? '، ': ', '