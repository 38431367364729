import * as Yup from 'yup'
import { showBedroomPropertyTypeSlug, listingValues } from '@/utils/constant'

export const shareFormSchema = (
  selectedDistrictFinal,
  t,
  isWasaltBusinessView,
  bankData,
  isName_Available,
  isPreApproved, 
  mortgageCheck
) =>
  Yup.object().shape({
    fullName:
      isWasaltBusinessView || isName_Available
        ? ''
        : Yup.string()
            .trim()
            .matches(/^['\u0621-\u064AA-Za-z ']+$/, t('eWallet.SPECIAL_CHARACTERS_NOT_ALLOWED'))
            .min(3, t('shareRequirement.FullName_Min_Length'))
            .max(150, t('postproperty.FullName_Max_Length'))
            .required(t('shareRequirement.Name_Validation')),
    propertyCategory: Yup.string().required(t('shareRequirement.Property_category_Errormsg')),
    propertyType: Yup.array().when('propertyCategory', {
      is: (propertyCategory) => propertyCategory && propertyCategory !== '',
      then: Yup.array()
        .min(1, t('shareRequirement.Property_type_Errormsg'))
        .required(t('shareRequirement.Property_type_Errormsg')),
    }),
    listingTypeId: Yup.string().required(t('shareRequirement.REQUIRED')),
    paymentType: bankData
      ? Yup.string().when('listingTypeId', {
          is: (listingTypeId) => listingTypeId && listingTypeId == listingValues[0],
          then: Yup.string().required(t('shareRequirement.Payment_Errormsg')),
        })
      : '',
    bedroomId: Yup.array()
      .of(Yup.string())
      .when('propertyType', {
        is: (propertyType) =>
          (propertyType && propertyType?.some((e) => showBedroomPropertyTypeSlug['residential'].includes(e))) ||
          propertyType?.some((e) => showBedroomPropertyTypeSlug['commercial'].includes(e)),
        then: Yup.array()
          .min(1, t('shareRequirement.Bedroom_Errormsg'))
          .required(t('shareRequirement.Bedroom_Errormsg')),
      }),
    cityId: Yup.string().required(t('shareRequirement.City_Errormsg')),
    district: Yup.string().when('cityId', {
      is: (cityId) => cityId && cityId !== '',
      then: Yup.string().test('required-with-data', t('shareRequirement.District_Errormsg'), () => !selectedDistrictFinal?.length ? false : true),
    }),
    maxPrice: Yup.number().required(t('shareRequirement.Budget_Errormsg')),
    terms: Yup.bool().oneOf([true], t('shareRequirement.REQUIRED')).required(t('shareRequirement.REQUIRED')),
    employmentMode: isPreApproved ? Yup.string().required(t('AlinmaBankPage.SELECT_VALID_OPTIONS')) : false,
    monthlySalary: isPreApproved
      ? Yup.number()
          .required(t('AlinmaBankPage.ENTER_YOUR_INCOME'))
          .test(
            'min-4000-check',
            t('AlinmaBankPage.MIN_INCOME_4000'),
            function (value) {
              if (mortgageCheck) {
                return value >= 4000;
              }
              return true; 
            }
          )
          .moreThan(0, t('AlinmaBankPage.INCOME_CANNOT_BE_ZERO'))
          .lessThan(10000000, t('AlinmaBankPage.MAX_MONTHLY_INCOME'))
      : false,
    age : isPreApproved && mortgageCheck ? Yup.number()
    .typeError('Age must be a number')  //no need of translation
    .min(21, t('AlinmaBankPage.AGE_RESTRICTION'))   
    .max(65, t('AlinmaBankPage.AGE_RESTRICTION'))
    .required(t('AlinmaBankPage.AGE_IS_REQUIRED')) : false,
  })

export const shareFormSchemaProfile = (t) =>
  Yup.object().shape({
    mobileNo: Yup.string()
      .min(9, t('postproperty.Phone_Min_Length'))
      .max(9, t('postproperty.Phone_Max_Length'))
      .matches(/^[5]\d+$/, t('common.mobile_number_start5'))
      .required(t('shareRequirement.REQUIRED')),
  })
